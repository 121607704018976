function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class CancellableTimeoutPrimise {
  timeoutId!: number;
  rejector: any;
  promise: Promise<any>;
  constructor(fun: Function, ms: number) {
    this.promise = new Promise((resolve, reject) => {
      this.timeoutId = window.setTimeout(() => {
        resolve(fun());
      }, ms);
      this.rejector = reject;
    });
  }

  cancel() {
    window.clearTimeout(this.timeoutId);
    this.rejector('auto_cancelled');
  }

  then() {
    return this.promise;
  }
}

class GlobalUtils {
  static uniq(array) {
    return array.filter(onlyUnique);
  }
  static assert(value: boolean, errorMsg: string) {
    if (!value) {
      throw new Error(errorMsg);
    }
  }

  static genUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static cancellablePromise(fun: Function, ms: number) {
    return new CancellableTimeoutPrimise(fun, ms);
  }

  static promisifyTimeout(fun: Function, ms: number) {
    return new Promise((resolve, reject) => {
      window.setTimeout(() => {
        resolve(fun());
      }, ms);
    });
  }

  static binarySearch<T>(item: T, searchArray: Array<T>): T | undefined {
    let startIndex = 0,
      endIndex = searchArray.length - 1,
      midPoint,
      midVal,
      searchResult;
    if (item < searchArray[startIndex]) {
      return undefined;
    }
    if (item >= searchArray[endIndex]) {
      return searchArray[searchArray.length - 1];
    }

    const searchAroundPoint = (midPoint: number, item: T) => {
      if (
        item == searchArray[midPoint] ||
        (midPoint < searchArray.length - 1 &&
          item > searchArray[midPoint] &&
          item < searchArray[midPoint + 1])
      )
        return midPoint;
      else if (
        midPoint - 1 >= 0 &&
        item >= searchArray[midPoint - 1] &&
        item < searchArray[midPoint]
      )
        return midPoint - 1;

      return undefined;
    };

    while (startIndex < endIndex) {
      midPoint = Math.floor((endIndex + startIndex) / 2);
      searchResult = searchAroundPoint(midPoint, item);
      if (searchResult == undefined) {
        if (item < searchArray[midPoint]) {
          endIndex = midPoint - 1;
        } else {
          startIndex = midPoint + 1;
        }
      } else {
        return searchArray[searchResult];
      }
    }
    return undefined;
  }

  static b64toBufferArray = (b64Data, contentType = '', sliceSize = 512) => {
    const binaryString = atob(b64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  static fileToBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
}

export default GlobalUtils;
