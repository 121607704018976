export default {
  title: 'Home',
  description: 'Delve into data and analytics',
  text: {
    videos_published: 'Videos Published',
    videos_draft: 'Videos in Draft',
    total_learners: 'Total Learners',
    total_views: 'Total Views',
    video_views: 'Video views',
    video_views_for_video: 'Video views for this video',
    guide_views_for_guide: 'Guide views',
    learners: 'Learners',
    learners_for_video: 'Learners for this video',
    learners_for_guide: 'Learners for this guide',
    new_learners: 'New Learners',
    total_hub_new_learners: 'Hub - New Learners',
    top_videos: 'Top Videos',
    top_learning_path: 'Top Collections',
    engagement_metrics: 'Engagement Metric',
    engagement_duration: 'Engagement Duration',
    total_engagement_duration: 'Total Enagagement Time',
    avg_engagement_duration: 'Average Enagagement Time Per User',
    hub_avg_engagement_duration: 'Hub - Average Enagagement Time Per User',
    range: 'Last 30 days',
    views: 'views',
    enrollments_copy: 'enrollments',
    completions_copy: 'completions',
    certified_copy: 'certifications',
    no_video_onboard:
      'Your dashboard seems empty! Start creating videos and train your customers!',
    share_video_onboard:
      'Your dashboard seems empty! Start sharing your videos!',
    start_sharing: 'Start sharing your collections!',
    start_sharing_videos: 'Start sharing your videos!',
    start_sharing_courses: 'Seems empty! Start sharing your courses!',
    note: 'Updated every 24 hours',
    video_analytics: 'Content Level Metrics',
    step_analytics: 'Video Block Level Drilled Down Metrics',
    chapter_analytics: 'Chapter Level Drilled Down Metrics',
    enrollments: 'Course Enrollments',
    completions: 'Course Completions',
    certifications: 'Certifications Issued',
    top_enrolled_courses: 'Top Enrolled Courses',
    top_certifications: 'Top Certified Courses',
    top_completed_courses: 'Top Completed Courses'
  }
};
