
import Vue from 'vue';
import Component from 'vue-class-component';
import TButton from '@/lib/form/button/Button.vue';
import TInput from '@/lib/form/input/Input.vue';
import apiClient from '@/app/clients/api-client';

@Component({
  name: 'create-folder-modal',
  components: {
    TButton,
    TInput,
  },
})
export default class CreateFolderModal extends Vue {
  folderName = 'Untitled Folder';
  invalidInput = false;
  loading = false;

  createFolder(event) {
    if (this.loading || !this.folderName) {
      this.invalidInput = true;
      return;
    }
    this.invalidInput = false;
    this.loading = true;
    event.preventDefault();
    event.stopPropagation();

    apiClient
      .setFolderV2({
        name: this.folderName,
        parent_id:
          this.$route.params.folderId ||
          this.$user.state.workspace.rootFolderId,
      })
      .then(({ content, meta }) => {
        this.$root.$emit('reset-folder-list');
        this.$root.$emit('update-folder', content);
        this.$root.$emit('update-meta', meta);

        this.folderName = 'Untitled Folder';
        this.loading = false;

        this.$modal.hide('create-folder');
      })
      .catch((err) => {
        this.loading = false;
        this.$error(err);
      });
  }
}
