const hasClass = (ele: any, cls: string) => {
  return (
    ele &&
    ele.className &&
    !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
  );
};

export const uuid = (): string => {
  let date = Date.now();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    date += performance.now();
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const random = (date + Math.random() * 16) % 16 | 0;
    date = Math.floor(date / 16);
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
  });
};

export const addClass = (ele: any, cls: string) => {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
};

export const removeClass = (ele: any, cls: string) => {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
};

const regex = /(auto|scroll)/;

const style = (node: HTMLElement, prop: string) =>
  getComputedStyle(node, null).getPropertyValue(prop);

const scroll = (node: HTMLElement) =>
  regex.test(
    style(node, 'overflow') +
      style(node, 'overflow-y') +
      style(node, 'overflow-x')
  );

export const scrollparent = (node: any): any =>
  !node || node === document.body
    ? document.body
    : scroll(node)
    ? node
    : scrollparent(node.parentNode);

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const unitArrayToString = arrayBuffer => {
  const TYPED_ARRAY = new Uint8Array(arrayBuffer);
  const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
    return data + String.fromCharCode(byte);
  }, '');
  const base64String = btoa(STRING_CHAR);
  return 'data:image/jpg;base64, ' + base64String;
};

const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


export const isValidTime = time => {
  const minutes = Number(time.split(':')[0]);
  const secs = Number(time.split(':')[1]);
  return !isNaN(minutes) && !isNaN(secs);
};

export const toSeconds = time => {
  if (!isValidTime(time)) {
    return;
  }
  const timeArray = time.split(':').reverse();
  const seconds = Number(timeArray[0] || 0);
  const minutes = Number(timeArray[1] || 0);
  const hours = Number(timeArray[2] || 0);

  return seconds + minutes * 60 + hours * 3600;
};
